<template>
  <b-card-code>
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permisos</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Guardar Cambiaos
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Resetear
    </b-button>
  </b-card-code>
</template>

<script>
import {
    BButton, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from './auxilary-roles/useUsersList'

export default {
    components: {
        BCardCode,
        BButton,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
    },
    setup(props) {
        const { resolveUserRoleVariant } = useUsersList()

        const roleOptions = [
            { label: 'Admin', value: 'admin' },
            { label: 'Author', value: 'author' },
            { label: 'Editor', value: 'editor' },
            { label: 'Maintainer', value: 'maintainer' },
            { label: 'Subscriber', value: 'subscriber' },
        ]

        const statusOptions = [
            { label: 'Pending', value: 'pending' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ]

        const permissionsData = [
            {
                module: 'Admin',
                read: true,
                write: false,
                create: false,
                delete: false,
            },
            {
                module: 'Staff',
                read: false,
                write: true,
                create: false,
                delete: false,
            },
            {
                module: 'Author',
                read: true,
                write: false,
                create: true,
                delete: false,
            },
            {
                module: 'Contributor',
                read: false,
                write: false,
                create: false,
                delete: false,
            },
            {
                module: 'User',
                read: false,
                write: false,
                create: false,
                delete: true,
            },
        ]

        // ? Demo Purpose => Update image on click of update
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
            // eslint-disable-next-line no-param-reassign
            props.userData.avatar = base64
        })

        return {
            resolveUserRoleVariant,
            avatarText,
            roleOptions,
            statusOptions,
            permissionsData,

            //  ? Demo - Update Image on click of update button
            refInputEl,
            previewEl,
            inputImageRenderer,
        }
    },
}
</script>

<style lang="scss">
</style>
